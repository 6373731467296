import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login/:code?',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/dashboard/:code?',
    name: 'DashBoard',
    component: () =>
      import(/* webpackChunkName: "DashBoard" */ '@/views/DashBoard.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue'
      )
  },
  {
    path: '/exclude-data/:code',
    name: 'ExcludeData',
    component: () =>
      import(/* webpackChunkName: "ExcludeData" */ '@/views/ExcludeData.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { loggedIn } = store.state.auth.status

  if (to.name === 'DashBoard' && !loggedIn) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
