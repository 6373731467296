<template>
  <footer class="the-footer">
    <div class="site-map">
      <router-link to="/home">Início</router-link>
      <router-link to="/privacypolicy">Política de Privacidade</router-link>
      <!-- <router-link to="/about">Sobre</router-link> -->
      <router-link to="/login">Entrar</router-link>
    </div>

    <div class="copyright">&copy; {{ year }} - Futura sistemas</div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="sass">
.the-footer
  display: flex
  justify-content: center
  flex-wrap: wrap
.copyright
  font-size: 14px
  width: 100%
  text-align: center
  margin: 24px 0
.site-map
  display: flex
  margin: 24px 0
  a
    margin-right: 24px
    text-decoration: none
    &:hover
      text-decoration: underline
</style>
