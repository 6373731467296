import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import '@/assets/reset.css'

window.fbAsyncInit = () => {
  window.FB.init({
    appId: '2486820544909228',
    cookie: true,
    xfbml: true,
    version: 'v10.0'
  })

  window.FB.AppEvents.logPageView()
}

const loadScriptFB = (d, s, id) => {
  const fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  const js = d.createElement(s)
  js.id = id
  js.src = 'https://connect.facebook.net/pt_BR/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}

loadScriptFB(document, 'script', 'facebook-jssdk')

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .component(TheHeader.name, TheHeader)
  .component(TheFooter.name, TheFooter)
  .mount('#app')
