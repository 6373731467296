<template>
  <TheHeader v-if="$route.path !== '/dashboard'" />
  <router-view />
  <TheFooter v-if="$route.path !== '/dashboard'" />
</template>

<style lang="sass">
html,
body,
#app
  height: 100%
body
  background-color: #fafafa
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  display: flex
  flex-direction: column
h1
  margin-bottom: 24px
</style>
