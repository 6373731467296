import axios from 'axios'

const API_URL = 'https://instagramec.futurasistemas.com.br/api/auth/'

class AuthService {
  login(user) {
    return new Promise((resolve, reject) => {
      localStorage.setItem('user', JSON.stringify(user))
      resolve(user)
    })

    // return axios
    //   .post(API_URL + 'signin', {
    //     username: user.username,
    //     password: user.password
    //   })
    //   .then(response => {
    //     if (response.data.accessToken) {
    //       localStorage.setItem('user', JSON.stringify(response.data))
    //     }

    //     return response.data
    //   })
  }

  logout() {
    localStorage.removeItem('user')
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    })
  }
}

export default new AuthService()
