<template>
  <header class="the-header">
    <router-link to="/home">
      <img
        src="https://www.futurasistemas.com.br/uploads/imagem/logotipo%20site.png"
        alt="Logo"
      />
    </router-link>
    <div class="nav">
      <router-link to="/home">Início</router-link>
      <!-- <router-link to="/about">Sobre</router-link> -->
      <router-link to="/login">Entrar</router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader'
}
</script>

<style scoped lang="sass">
.the-header
  background-color: #fff
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px 24px
  box-shadow: 0 0.6rem 0.9rem -0.15rem rgba(47, 72, 88, .1), 0 0.3rem 0.45rem -0.7rem rgba(47, 72, 88, .05)

.nav
  display: flex
  a
    font-weight: bold
    color: #216494
    padding: 8px 12px
    text-decoration: none
    &:hover
      color: #cccccc
    &.router-link-exact-active
      color: #D17825
</style>
